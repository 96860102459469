import React, { useState, useEffect, useCallback } from 'react';
import commonStyles from './BookingStepCommon.module.css';
import styles from './PersonalDetailsStep.module.css';
import buttonStyles from '../BookingStepButtons.module.css';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

const PersonalDetailsStep = ({ personalDetails, onInputChange, onNext, onPrev }) => {
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedDetails = {
      ...personalDetails,
      [name]: value
    };

    if (name === 'firstName' || name === 'lastName') {
      updatedDetails.fullName = `${updatedDetails.firstName || ''} ${updatedDetails.lastName || ''}`.trim();
    }

    onInputChange('personalDetails', updatedDetails);
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    const updatedLocation = {
      ...personalDetails.cleaningLocation,
      [name]: value
    };
    onInputChange('personalDetails', {
      ...personalDetails,
      cleaningLocation: updatedLocation
    });
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!personalDetails.firstName) newErrors.firstName = 'First name is required';
    if (!personalDetails.lastName) newErrors.lastName = 'Last name is required';
    if (!personalDetails.email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(personalDetails.email)) newErrors.email = 'Email is invalid';
    if (!personalDetails.phone) newErrors.phone = 'Phone number is required';
    if (!personalDetails.cleaningLocation.streetAddress) newErrors.streetAddress = 'Street address is required';
    if (!personalDetails.cleaningLocation.suburb) newErrors.suburb = 'Suburb is required';
    if (!personalDetails.cleaningLocation.state) newErrors.state = 'State is required';
    if (!personalDetails.cleaningLocation.postcode) newErrors.postcode = 'Postcode is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [personalDetails]);

  useEffect(() => {
    validateForm();
  }, [personalDetails, validateForm]);

  const handleNext = () => {
    if (validateForm()) {
      onNext();
    }
  };

  const renderInput = (name, label, icon, value, onChange, error) => (
    <div className={styles.inputGroup}>
      <label htmlFor={name} className={styles.label}>
        {icon}
        {label}
      </label>
      <input
        type="text"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={`${styles.input} ${error ? styles.inputError : ''}`}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );

  return (
    <div className={commonStyles.stepContainer}>
      <h2 className={commonStyles.stepTitle}>Enter Personal Details</h2>
      <form className={styles.form}>
        <div className={styles.formGrid}>
          {renderInput('firstName', 'First Name', <FaUser />, personalDetails.firstName, handleChange, errors.firstName)}
          {renderInput('lastName', 'Last Name', <FaUser />, personalDetails.lastName, handleChange, errors.lastName)}
          {renderInput('email', 'Email', <FaEnvelope />, personalDetails.email, handleChange, errors.email)}
          {renderInput('phone', 'Phone', <FaPhone />, personalDetails.phone, handleChange, errors.phone)}
        </div>
        <h3 className={styles.subheading}>Cleaning Location</h3>
        <div className={styles.formGrid}>
          {renderInput('streetAddress', 'Street Address', <FaMapMarkerAlt />, personalDetails.cleaningLocation.streetAddress, handleLocationChange, errors.streetAddress)}
          {renderInput('suburb', 'Suburb', <FaMapMarkerAlt />, personalDetails.cleaningLocation.suburb, handleLocationChange, errors.suburb)}
          {renderInput('state', 'State', <FaMapMarkerAlt />, personalDetails.cleaningLocation.state, handleLocationChange, errors.state)}
          {renderInput('postcode', 'Postcode', <FaMapMarkerAlt />, personalDetails.cleaningLocation.postcode, handleLocationChange, errors.postcode)}
        </div>
      </form>
      <div className={buttonStyles.navigationButtons}>
        <button onClick={onPrev} className={`${buttonStyles.button} ${buttonStyles.secondaryButton}`}>
          Previous
        </button>
        <button
          onClick={handleNext}
          className={`${buttonStyles.button} ${buttonStyles.primaryButton}`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PersonalDetailsStep;

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.js';
import './index.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  
  try {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Error rendering the app:', error);
    root.render(
      <div>
        <h1>Oops! Something went wrong.</h1>
        <p>We're sorry, but we couldn't load the application. Please try refreshing the page.</p>
      </div>
    );
  }
} else {
  console.error('Root element not found');
}

export async function sendToZapier(data) {
  console.log('Sending data to Zapier:', data);
  try {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
    const response = await fetch(`${apiBaseUrl}/api/zapierHandler`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response body:', errorText);
      throw new Error(`Failed to submit data to API. Status: ${response.status}, Error: ${errorText}`);
    }

    const responseData = await response.json();
    console.log('API response:', responseData);
    return responseData;
  } catch (error) {
    console.error('Error sending data to API:', error);
    throw error;
  }
}
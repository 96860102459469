import { FaHome, FaBuilding, FaTree, FaCouch, FaBroom, FaSink, FaShower, FaWindowMaximize, FaClipboardCheck, FaSprayCan, FaLeaf, FaRecycle, FaPumpSoap, FaChair, FaTshirt, FaCut } from 'react-icons/fa';
import { GiVacuumCleaner, GiWateringCan, GiGardeningShears } from 'react-icons/gi';

// Services data structure
export const servicesData = [
  {
    id: "deep-residential-cleaning",
    name: 'Deep Residential Cleaning',
    category: 'Residential',
    icon: FaHome,
    description: 'Our premium deep residential cleaning service covers every aspect of your home, including carpet and upholstery cleaning, and strata cleaning. We use premium products and advanced techniques to ensure a thorough clean that refreshes your living space.',
    features: [
      'Comprehensive deep cleaning of all rooms',
      'Carpet and upholstery deep cleaning',
      'Strata cleaning services',
      'Premium cleaning products',
      'Attention to detail in hard-to-reach areas'
    ],
    images: [
      '/images/services/deep-residential-cleaning-1.jpg',
      '/images/services/deep-residential-cleaning-2.jpg',
      '/images/services/deep-residential-cleaning-3.jpg',
      '/images/services/ServicePageServicesWeOfferDeep1.jpg',
      '/images/services/ServicePageServicesWeOfferDeep2.jpg',
      '/images/services/ServicePageServicesWeOfferResidential2.jpg'
    ],
    whyChooseUs: `Choose our Deep Residential Cleaning service for a transformative cleaning experience. 
      Our skilled team uses eco-friendly products and cutting-edge techniques to deliver exceptional results. 
      We pay attention to every detail, ensuring even the most overlooked areas of your home are spotless. 
      With our service, you'll enjoy a healthier, more comfortable living environment and the peace of mind that comes with a truly clean home.`,
    homePageContent: {
      category: 'RESIDENTIAL',
      title: 'Deep Residential Cleaning',
      description: 'Comprehensive cleaning for homes and apartments, ensuring a spotless and comfortable living space.',
      image: '/images/home/HomePageOurServiceResidentialCleaning.jpg',
    },
    subServices: [
      {
        id: 'basic',
        name: "Basic Clean",
        description: "Dusting, vacuuming, and basic bathroom and kitchen cleaning",
        icon: FaBroom
      },
      {
        id: 'deep_kitchen',
        name: "Deep Kitchen Clean",
        description: "Detailed cleaning of all kitchen surfaces and appliances",
        icon: FaSink
      },
      {
        id: 'deep_bathroom',
        name: "Deep Bathroom Clean",
        description: "Detailed cleaning and sanitising of all bathroom surfaces",
        icon: FaShower
      },
      {
        id: 'windows',
        name: "Window Cleaning",
        description: "Interior window cleaning",
        icon: FaWindowMaximize
      },
      {
        id: 'carpets',
        name: "Carpet Cleaning",
        description: "Deep carpet cleaning in selected rooms",
        icon: FaCouch
      }
    ],
  },
  {
    id: "deep-commercial-cleaning",
    name: 'Deep Commercial Cleaning',
    category: 'Commercial',
    icon: FaBuilding,
    description: 'Our premium deep commercial cleaning service is designed to maintain a pristine and hygienic environment for businesses of all sizes. We offer thorough cleaning of all commercial spaces, including offices, retail stores, and industrial facilities.',
    features: [
      'Comprehensive cleaning of all commercial areas',
      'Sanitisation of high-touch surfaces',
      'Floor care for various types of flooring',
      'Window and glass cleaning',
      'Customised cleaning plans for each business'
    ],
    images: [
      '/images/services/deep-commercial-cleaning-1.jpg',
      '/images/services/deep-commercial-cleaning-2.jpg',
      '/images/services/deep-commercial-cleaning-3.jpg',
      '/images/services/ServicePageServicesDeepCommercial3.jpg'
    ],
    whyChooseUs: `Our Deep Commercial Cleaning service is the ideal choice for businesses that prioritize cleanliness and professionalism. 
      We understand that a clean workspace enhances productivity and leaves a lasting impression on clients and employees alike. 
      Our team is trained to handle the unique cleaning needs of various commercial environments, ensuring minimal disruption to your business operations while delivering superior cleaning results.`,
    homePageContent: {
      category: 'COMMERCIAL',
      title: 'Deep Commercial Cleaning',
      description: 'Professional cleaning services for businesses and offices, maintaining a pristine work environment.',
      image: '/images/home/HomePageOurServiceCommercialCleaning.jpg',
    },
    subServices: [
      {
        id: 'basic',
        name: "Basic Office Clean",
        description: "Dusting, vacuuming, rubbish removal, and restroom cleaning",
        icon: FaClipboardCheck
      },
      {
        id: 'deep_clean',
        name: "Deep Clean",
        description: "Detailed cleaning of all surfaces, including disinfection",
        icon: FaSprayCan
      },
      {
        id: 'windows',
        name: "Window Cleaning",
        description: "Interior and exterior window cleaning",
        icon: FaWindowMaximize
      },
      {
        id: 'carpet',
        name: "Carpet Cleaning",
        description: "Deep carpet cleaning for office areas",
        icon: GiVacuumCleaner
      },
      {
        id: 'hard_floors',
        name: "Hard Floor Cleaning",
        description: "Cleaning and polishing of hard floor surfaces",
        icon: FaBroom
      }
    ],
  },
  {
    id: "lawn-backyard-maintenance",
    name: 'Lawn and Backyard Maintenance',
    category: 'Outdoor',
    icon: FaTree,
    description: 'Our lawn and backyard maintenance service ensures your outdoor spaces look as immaculate as your indoor areas. We offer comprehensive care for your garden, lawn, and outdoor living areas to create a beautiful and welcoming environment.',
    features: [
      'Professional lawn mowing and edging',
      'Garden bed maintenance and planting',
      'Pruning of trees and shrubs',
      'Weed control and fertilisation',
      'Outdoor area cleaning and organisation'
    ],
    images: [
      '/images/services/lawn-backyard-maintenance-1.jpg',
      '/images/services/lawn-backyard-maintenance-2.jpg',
      '/images/services/lawn-backyard-maintenance-3.jpg',
      '/images/services/ServicePageLawnBackyard1.jpg'
    ],
    whyChooseUs: `Our Lawn and Backyard Maintenance service is perfect for those who want to enjoy a beautiful outdoor space without the hassle of maintaining it themselves. 
      Our experienced landscapers have the knowledge and tools to keep your garden thriving year-round. 
      We take pride in creating outdoor spaces that enhance your property's beauty and provide a relaxing environment for you to enjoy.`,
    homePageContent: {
      category: 'OUTDOOR',
      title: 'Lawn and Backyard Maintenance',
      description: 'Comprehensive care for your outdoor spaces, ensuring a beautiful and welcoming environment.',
      image: '/images/services/HomePageOurServiceLawnCleaning.jpg',
    },
    subServices: [
      {
        id: 'lawn_mowing',
        name: "Lawn Mowing",
        description: "Professional mowing and edging for a manicured look",
        icon: FaCut
      },
      {
        id: 'garden_maintenance',
        name: "Garden Maintenance",
        description: "Pruning, planting, and care for garden beds",
        icon: FaLeaf
      },
      {
        id: 'tree_care',
        name: "Tree and Shrub Care",
        description: "Pruning and shaping of trees and shrubs",
        icon: GiGardeningShears
      },
      {
        id: 'weed_control',
        name: "Weed Control",
        description: "Removal and prevention of weeds in lawn and garden areas",
        icon: GiWateringCan
      },
      {
        id: 'outdoor_cleaning',
        name: "Outdoor Area Cleaning",
        description: "Cleaning of patios, decks, and other outdoor living spaces",
        icon: FaRecycle
      }
    ],
  },
  {
    id: "upholstery-carpet-cleaning",
    name: 'Upholstery and Carpet Cleaning',
    category: 'Interior',
    icon: FaCouch,
    description: 'Our specialised upholstery and carpet cleaning service uses advanced techniques and premium products to deep clean and refresh your carpets, rugs, and upholstered furniture, removing stains, odours, and allergens.',
    features: [
      'Deep cleaning of carpets, rugs, and upholstered furniture',
      'Stain and odour removal',
      'Allergen reduction',
      'Use of premium cleaning solutions',
      'Hot water extraction method for thorough cleaning'
    ],
    images: [
      '/images/services/upholstery-carpet-cleaning-1.jpg',
      '/images/services/upholstery-carpet-cleaning-2.jpg',
      '/images/services/upholstery-carpet-cleaning-3.jpg',
      '/images/services/ServicePageCarpetUpholsteryCleaning1.jpg',
      '/images/services/ServicePageCarpetUpholsteryCleaning2.jpg'
    ],
    whyChooseUs: `Our Upholstery and Carpet Cleaning service is the perfect solution for revitalizing your home's fabrics and flooring. 
      We use state-of-the-art equipment and eco-friendly cleaning solutions to remove deep-seated dirt, stains, and allergens. 
      Our service not only improves the appearance of your carpets and furniture but also extends their lifespan, making it a smart investment in your home's comfort and value.`,
    homePageContent: {
      category: 'INTERIOR',
      title: 'Upholstery and Carpet Cleaning',
      description: 'Revitalise your carpets and furniture with our professional deep cleaning service.',
      image: '/images/home/HomePageOurServiceUpholsteryCarpetCleaning.jpg',
    },
    subServices: [
      {
        id: 'carpet_cleaning',
        name: "Carpet Cleaning",
        description: "Deep cleaning of carpets using hot water extraction method",
        icon: GiVacuumCleaner
      },
      {
        id: 'rug_cleaning',
        name: "Rug Cleaning",
        description: "Specialised cleaning for various types of rugs",
        icon: FaCouch
      },
      {
        id: 'upholstery_cleaning',
        name: "Upholstery Cleaning",
        description: "Deep cleaning of sofas, chairs, and other upholstered furniture",
        icon: FaChair
      },
      {
        id: 'stain_removal',
        name: "Stain Removal",
        description: "Targeted treatment for tough stains on carpets and upholstery",
        icon: FaTshirt
      },
      {
        id: 'odor_treatment',
        name: "Odour Treatment",
        description: "Elimination of odours from carpets and upholstered items",
        icon: FaPumpSoap
      }
    ],
  }
];

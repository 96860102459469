import React, { useState, useCallback, useMemo, lazy, Suspense, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styles from './Header.module.css';

// Components
const Logo = React.memo(lazy(() => import('../shared/Logo')));
const BookNowButton = React.memo(lazy(() => import('../shared/BookNowButton')));

const NAV_ITEMS = [
  { to: '/', text: 'Home' },
  { to: '/services', text: 'Services' },
  { to: '/about', text: 'About Us' },
  { to: '/contact', text: 'Contact' },
];

const Header = React.memo(() => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();

  const toggleNavbar = useCallback(() => {
    setNavbarOpen(prev => !prev);
    document.body.style.overflow = navbarOpen ? 'visible' : 'hidden';
  }, [navbarOpen]);

  const memoizedNavItems = useMemo(() => NAV_ITEMS, []);

  const closeNavbar = useCallback(() => {
    setNavbarOpen(false);
    document.body.style.overflow = 'visible';
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={`${styles.headerWrapper} ${isSticky ? styles.sticky : ''}`}>
        <div className={styles.container}>
          <div className={styles.headerContent}>
            <div className={styles.leftSection}>
              <div className={styles.logoTitleContainer}>
                <Suspense fallback={<div className={styles.logoPlaceholder} aria-hidden="true"></div>}>
                  <Logo />
                </Suspense>
                <Link to="/" className={styles.titleLink}>
                  <h1 className={styles.title}>OzCleanery</h1>
                </Link>
              </div>
            </div>
            <div className={styles.rightSection}>
              <div className={styles.navTogglerContainer}>
                <button 
                  className={`${styles.navbarToggler} ${navbarOpen ? styles.active : ''}`} 
                  onClick={toggleNavbar} 
                  aria-expanded={navbarOpen}
                  aria-controls="navigation-menu"
                  aria-label="Toggle navigation menu"
                >
                  <span className={styles.burgerIcon}></span>
                </button>
              </div>
            </div>
            <nav
              className={`${styles.navigation} ${navbarOpen ? styles.open : ''}`}
              aria-label="Main navigation"
              id="navigation-menu"
            >
              <ul className={styles.navList} role="menu">
                {memoizedNavItems.map((item, index) => (
                  <li key={index} className={styles.navItem} role="none">
                    <NavLink
                      to={item.to}
                      className={({ isActive }) => `${styles.navLink} ${isActive ? styles.active : ''}`}
                      onClick={() => {
                        closeNavbar();
                        if (location.pathname === item.to) {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                      }}
                      role="menuitem"
                    >
                      {item.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <div className={styles.mobileBookNow}>
                <Suspense fallback={<div className={styles.buttonPlaceholder} aria-hidden="true"></div>}>
                  <BookNowButton closeNavbar={closeNavbar} />
                </Suspense>
              </div>
            </nav>
            <div className={styles.desktopBookNow}>
              <Suspense fallback={<div className={styles.buttonPlaceholder} aria-hidden="true"></div>}>
                <BookNowButton closeNavbar={closeNavbar} />
              </Suspense>
            </div>
          </div>
        </div>
      </header>
      {navbarOpen && <div className={styles.overlay} onClick={closeNavbar} aria-hidden="true"></div>}
    </>
  );
});

export default Header;

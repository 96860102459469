import { validateEmail, validatePhone } from './validation';
// Removed unused import
// import config from '../config'; // Commented out if unnecessary

export const validateForm = (formData, requiredFields, customValidations = {}) => {
  if (!formData) {
    console.error('Form data is undefined');
    return { general: 'An error occurred while validating the form' };
  }

  let errors = {};
  
  if (requiredFields && Array.isArray(requiredFields)) {
    requiredFields.forEach(field => {
      if (!formData[field] || (typeof formData[field] === 'string' && formData[field].trim() === '')) {
        errors[field] = `${field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} is required`;
      }
    });
  }

  if (formData.email && !validateEmail(formData.email)) {
    errors.email = 'Invalid email address';
  }

  if (formData.phone && !validatePhone(formData.phone)) {
    errors.phone = 'Invalid phone number';
  }

  // Add any custom validations
  Object.entries(customValidations).forEach(([field, validationFn]) => {
    const error = validationFn(formData[field]);
    if (error) {
      errors[field] = error;
    }
  });

  return errors;
};

export const handleInputChange = (input, formData, setFormData) => {
  const { name, value } = input;
  setFormData(prevData => ({
    ...prevData,
    [name]: value
  }));
  return { ...formData, [name]: value };
};

export const handleFormSubmit = async (e, formData, requiredFields, customValidations, setErrors, setSubmitStatus, onSubmit) => {
  e.preventDefault();
  const errors = validateForm(formData, requiredFields, customValidations);
  
  if (Object.keys(errors).length === 0) {
    setSubmitStatus('submitting');
    try {
      await onSubmit(formData);
      setSubmitStatus('success');
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
  } else {
    setErrors(errors);
  }
};

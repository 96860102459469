export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone) => {
  const re = /^(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return re.test(String(phone));
};

export const validateDate = (date) => {
  const selectedDate = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  if (selectedDate < today) {
    return 'Please select a future date';
  }
  return null;
};

export const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasNonalphas = /\W/.test(password);

  if (password.length < minLength) {
    return 'Password must be at least 8 characters long';
  }
  if (!hasUpperCase || !hasLowerCase || !hasNumbers || !hasNonalphas) {
    return 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character';
  }
  return null;
};

export const validateBookingDate = (date) => {
  const selectedDate = new Date(date);
  const currentDate = new Date();
  if (selectedDate < currentDate) {
    return 'Booking date cannot be in the past';
  }
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(threeMonthsFromNow.getMonth() + 3);
  if (selectedDate > threeMonthsFromNow) {
    return 'Booking date cannot be more than 3 months in the future';
  }
  return null;
};

export const validatePostcode = (postcode) => {
  return /^\d{4}$/.test(postcode) ? null : 'Invalid postcode. Must be 4 digits';
};

export const validateName = (name) => {
  return name.length >= 2 ? null : 'Name must be at least 2 characters long';
};

import React from 'react';
import styles from './ErrorBoundary.module.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorMessage = error.message || 'An unknown error occurred';
    const errorStack = error.stack || '';
    this.setState({ error, errorInfo });
    console.error('Error caught by ErrorBoundary:', errorMessage, errorStack, errorInfo);

    // Send error to our logging service
    this.logErrorToService(errorMessage, errorStack, errorInfo);
  }

  logErrorToService(errorMessage, errorStack, errorInfo) {
    console.log('Logging error to service:', {
      message: errorMessage,
      stack: errorStack,
      componentStack: errorInfo.componentStack
    });
    // TODO: Implement actual error logging service call
  }

  isChunkLoadError(error) {
    return error.name === 'ChunkLoadError' || error.message.includes('Loading chunk') || error.message.includes('Unexpected token');
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      const isChunkError = this.isChunkLoadError(this.state.error);
      return (
        <div className={styles.errorContainer}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            {isChunkError
              ? "We're having trouble loading some content. This might be due to a network issue."
              : "We're sorry for the inconvenience. Please try again later or contact support if the problem persists."}
          </p>
          {isChunkError && (
            <button className={styles.retryButton} onClick={this.handleRetry}>
              Retry Loading
            </button>
          )}
          {process.env.NODE_ENV === 'development' && (
            <details className={styles.errorDetails}>
              <summary>Error Details</summary>
              <pre>{this.state.error && this.state.error.toString()}</pre>
              <pre>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
            </details>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { useState, useCallback, memo, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';
import { faqData as allFaqData } from '../../data/faqData';
import styles from './FAQ.module.css';
import SectionTitle from '../SectionTitle/SectionTitle';

const FAQ = ({ faqData = allFaqData }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = useCallback((index) => {
    setOpenIndex(prevIndex => prevIndex === index ? null : index);
  }, []);

  return (
    <section className={styles.faqSection}>
      <div className={styles.faqContainer}>
        <SectionTitle>Frequently Asked Questions</SectionTitle>
        <div className={styles.faqList}>
          {faqData.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const FAQItem = memo(({ question, answer, isOpen, onToggle }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const contentEl = contentRef.current;
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isOpen]);

  return (
    <div className={styles.faqItem}>
      <button
        className={`${styles.faqQuestion} ${isOpen ? styles.open : ''}`}
        onClick={onToggle}
        aria-expanded={isOpen}
      >
        <span className={styles.questionText}>{question}</span>
        <motion.span 
          className={styles.faqIcon}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <FaChevronDown />
        </motion.span>
      </button>
      <motion.div
        className={styles.faqAnswer}
        animate={{ height }}
        transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        <div ref={contentRef} className={styles.faqAnswerContent}>
          <p>{answer}</p>
        </div>
      </motion.div>
    </div>
  );
});

export default memo(FAQ);

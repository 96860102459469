import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import styles from './ContactForm.module.css';
import { handleInputChange } from '../../utils/formUtils';
import { validateName, validateEmail, validatePhone, validateDate } from '../../utils/validation';
import { servicesData } from '../../data/servicesData';

const ContactForm = ({ isBooking = false }) => {
  const { serviceId } = useParams();
  const service = isBooking ? servicesData.find(s => s.id === parseInt(serviceId)) : null;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    address: '',
    message: '',
    serviceId: serviceId
  });
  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState(null);

  const requiredFields = isBooking 
    ? ['name', 'email', 'phone', 'date', 'time', 'address']
    : ['name', 'email', 'message'];
  const customValidations = {
    name: validateName,
    email: validateEmail,
    phone: validatePhone,
    date: validateDate
  };

  const handleChange = (e) => {
    handleInputChange(e.target, formData, setFormData);
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: null });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate fields
    let newErrors = {};
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      } else if (customValidations[field]) {
        const validationResult = customValidations[field](formData[field]);
        if (!validationResult.isValid) {
          newErrors[field] = validationResult.message;
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setSubmitStatus('submitting');

    try {
      const response = await axios.post('/api/submitContactForm', formData);
      if (response.data.message === 'Contact form submitted successfully') {
        setSubmitStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          date: '',
          time: '',
          address: '',
          message: '',
          serviceId: serviceId
        });
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
  };

  if (isBooking && !service) {
    return <div>Service not found</div>;
  }

  return (
    <motion.section
      className={styles.formSection}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className={styles.formTitle}>{isBooking ? `Book ${service.title}` : 'Contact Us'}</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        {['name', 'email', 'phone'].map((field) => (
          <div key={field} className={styles.formGroup}>
            <label htmlFor={field} className={styles.label}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
            <input
              type={field === 'email' ? 'email' : field === 'phone' ? 'tel' : 'text'}
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className={`${styles.input} ${errors[field] ? styles.errorInput : ''}`}
              placeholder={`Your ${field}`}
              aria-required="true"
              aria-invalid={errors[field] ? "true" : "false"}
            />
            <AnimatePresence>
              {errors[field] && (
                <motion.span
                  className={styles.errorMessage}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  role="alert"
                >
                  {errors[field]}
                </motion.span>
              )}
            </AnimatePresence>
          </div>
        ))}
        {isBooking && (
          <>
            {['date', 'time'].map((field) => (
              <div key={field} className={styles.formGroup}>
                <label htmlFor={field} className={styles.label}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                <input
                  type={field}
                  id={field}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  className={`${styles.input} ${errors[field] ? styles.errorInput : ''}`}
                  aria-required="true"
                  aria-invalid={errors[field] ? "true" : "false"}
                />
                <AnimatePresence>
                  {errors[field] && (
                    <motion.span
                      className={styles.errorMessage}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      role="alert"
                    >
                      {errors[field]}
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
            ))}
            <div className={styles.formGroup}>
              <label htmlFor="address" className={styles.label}>Address</label>
              <textarea
                id="address"
                name="address"
                rows="3"
                value={formData.address}
                onChange={handleChange}
                className={`${styles.textarea} ${errors.address ? styles.errorInput : ''}`}
                placeholder="Your address..."
                aria-required="true"
                aria-invalid={errors.address ? "true" : "false"}
              ></textarea>
              <AnimatePresence>
                {errors.address && (
                  <motion.span
                    className={styles.errorMessage}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    role="alert"
                  >
                    {errors.address}
                  </motion.span>
                )}
              </AnimatePresence>
            </div>
          </>
        )}
        {!isBooking && (
          <div className={styles.formGroup}>
            <label htmlFor="message" className={styles.label}>Message</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              className={`${styles.textarea} ${errors.message ? styles.errorInput : ''}`}
              placeholder="Your message..."
              aria-required="true"
              aria-invalid={errors.message ? "true" : "false"}
            ></textarea>
            <AnimatePresence>
              {errors.message && (
                <motion.span
                  className={styles.errorMessage}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  role="alert"
                >
                  {errors.message}
                </motion.span>
              )}
            </AnimatePresence>
          </div>
        )}
        <motion.button
          type="submit"
          className={styles.submitButton}
          disabled={submitStatus === 'submitting'}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          aria-live="polite"
        >
          {submitStatus === 'submitting' ? (
            <>
              <span className={styles.spinner} aria-hidden="true"></span>
              {isBooking ? 'Booking...' : 'Sending...'}
            </>
          ) : (
            isBooking ? 'Book Now' : 'Send Message'
          )}
        </motion.button>
      </form>
      <AnimatePresence>
        {submitStatus === 'success' && (
          <motion.div
            className={styles.successMessage}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            {isBooking ? 'Booking successful! We\'ll contact you soon to confirm.' : 'Message sent successfully! We\'ll get back to you soon.'}
          </motion.div>
        )}
        {submitStatus === 'error' && (
          <motion.div
            className={styles.errorMessage}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            An error occurred. Please try again later.
          </motion.div>
        )}
      </AnimatePresence>
    </motion.section>
  );
};

export default ContactForm;

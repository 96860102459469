export const faqData = [
  {
    question: "What cleaning services does OzCleanery offer?",
    answer: "OzCleanery offers a wide range of cleaning services including Deep Residential Cleaning, Deep Commercial Cleaning, Lawn and Backyard Maintenance, and Upholstery and Carpet Cleaning. Our services cater to both residential and commercial needs, ensuring pristine indoor and outdoor spaces."
  },
  {
    question: "What does the Deep Residential Cleaning service include?",
    answer: "Our Deep Residential Cleaning service covers comprehensive cleaning of all rooms, carpet and upholstery deep cleaning, strata cleaning services, and uses premium cleaning products. We pay special attention to detail in hard-to-reach areas to ensure a thorough clean that refreshes your living space."
  },
  {
    question: "Do you offer cleaning services for businesses?",
    answer: "Yes, we offer Deep Commercial Cleaning services designed for businesses of all sizes. This includes thorough cleaning of offices, retail stores, and industrial facilities. We provide comprehensive cleaning of all commercial areas, sanitisation of high-touch surfaces, floor care, window cleaning, and customised cleaning plans for each business."
  },
  {
    question: "What outdoor services do you provide?",
    answer: "We offer Lawn and Backyard Maintenance services to keep your outdoor spaces looking immaculate. This includes professional lawn mowing and edging, garden bed maintenance and planting, pruning of trees and shrubs, weed control and fertilisation, and cleaning of outdoor living areas."
  },
  {
    question: "Can you clean carpets and upholstery?",
    answer: "Absolutely! Our Upholstery and Carpet Cleaning service uses advanced techniques and premium products to deep clean and refresh your carpets, rugs, and upholstered furniture. We offer stain and odour removal, allergen reduction, and use hot water extraction method for thorough cleaning."
  },
  {
    question: "Do you use eco-friendly cleaning products?",
    answer: "Yes, we prioritise the use of premium, environmentally friendly cleaning products that are safe for your family and pets while still providing excellent cleaning results."
  },
  {
    question: "How can I book a cleaning service with OzCleanery?",
    answer: "You can book our services easily through our website or by calling our customer service number. We offer a simple and quick booking process to ensure convenience for our customers."
  },
  {
    question: "Do you offer recurring cleaning services?",
    answer: "Yes, we offer recurring cleaning services for both residential and commercial clients. You can schedule regular cleaning sessions on a weekly, fortnightly, or monthly basis, depending on your needs."
  },
  {
    question: "Are your cleaning staff trained and insured?",
    answer: "Absolutely. All our cleaning staff undergo rigorous training and are fully insured. We ensure they are knowledgeable about the latest cleaning techniques and safety protocols to provide you with the best possible service."
  },
  {
    question: "Do you provide your own cleaning supplies and equipment?",
    answer: "Yes, we bring all necessary cleaning supplies and equipment. However, if you have specific products you prefer us to use, we're happy to accommodate your requests."
  },
  {
    question: "How do you handle keys and access to properties?",
    answer: "We have strict protocols for handling keys and accessing properties. All key handovers are documented, and keys are stored securely when not in use. We can also work with keyless entry systems if you prefer."
  },
  {
    question: "What if I'm not satisfied with the cleaning service?",
    answer: "Your satisfaction is our top priority. If you're not completely satisfied with our service, please let us know within 24 hours, and we'll return to address any areas of concern at no additional cost."
  },
  {
    question: "Do you offer end of lease cleaning services?",
    answer: "Yes, we offer comprehensive end of lease cleaning services. Our team ensures that the property meets the high standards required by landlords and real estate agents, helping you secure your bond return."
  },
  {
    question: "Can you accommodate special cleaning requests?",
    answer: "Certainly! We understand that each property and client has unique needs. We're always happy to discuss and accommodate special cleaning requests to ensure you receive a tailored service."
  },
  {
    question: "How do you ensure the security of my property during cleaning?",
    answer: "We take property security very seriously. All our staff undergo background checks, and we have strict protocols in place for entering and exiting properties. We're also fully insured for your peace of mind."
  }
];

import React, { useState, useRef, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import { SectionTitle } from '../shared/SectionTitle';
import styles from './Testimonials.module.css';
import testimonialsData from '../../data/testimonialData';

const Testimonials = ({ sectionTitle = "What Our Customers Say" }) => {
  const [expanded, setExpanded] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer && !expanded) {
      const scrollWidth = scrollContainer.scrollWidth;
      const animationDuration = scrollWidth / 30; // Adjust speed as needed
      scrollContainer.style.setProperty('--scroll-width', `${scrollWidth}px`);
      scrollContainer.style.setProperty('--animation-duration', `${animationDuration}s`);
    }
  }, [expanded]);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={index < rating ? styles.starFilled : styles.starEmpty}
      />
    ));
  };

  const renderTestimonialCard = (testimonial, index) => (
    <div key={`${testimonial.id}-${index}`} className={styles.testimonialCard}>
      <p className={styles.testimonialText}>{testimonial.quote}</p>
      <div className={styles.testimonialFooter}>
        <div className={styles.testimonialRating}>
          {renderStars(testimonial.rating)}
        </div>
        <div className={styles.testimonialAuthor}>
          <p className={styles.authorName}>{testimonial.author}</p>
          <p className={styles.authorArea}>{testimonial.location}</p>
        </div>
      </div>
    </div>
  );

  // Create a looped array of testimonials for scrolling
  const loopedTestimonials = [...testimonialsData, ...testimonialsData];

  return (
    <section className={styles.testimonials}>
      <div className={styles.container}>
        <div className={styles.testimonialTitle}>
          <SectionTitle>{sectionTitle}</SectionTitle>
        </div>
        <div 
          className={`${styles.testimonialScroll} ${expanded ? styles.expanded : ''}`}
          ref={scrollRef}
        >
          <div className={styles.scrollContent}>
            {expanded
              ? testimonialsData.map((testimonial, index) => renderTestimonialCard(testimonial, index))
              : loopedTestimonials.map((testimonial, index) => renderTestimonialCard(testimonial, index))
            }
          </div>
        </div>
        <div className={styles.controlsContainer}>
          <button 
            className={styles.controlButton} 
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Close' : 'View All'}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

import React, { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ServiceGridStep from './Steps/ServiceGridStep';
import ServiceSelectionStep from './Steps/ServiceSelectionStep';
import PersonalDetailsStep from './Steps/PersonalDetailsStep';
import DateTimeSelectionStep from './Steps/DateTimeSelectionStep';
import SummaryStep from './Steps/SummaryStep';
import styles from './BookingForm.module.css';
import { servicesData } from '../../data/servicesData';
import { useBookingForm } from '../../hooks/useBookingForm';

const stepDescriptions = [
  "Choose Cleaning Category",
  "Customise Your Service",
  "Enter Personal Details",
  "Select Date and Time",
  "Review and Submit"
];

const BookingFormPage = () => {
  const location = useLocation();
  const {
    step,
    bookingData,
    isSubmitting,
    bookingError,
    fieldErrors,
    nextStep,
    prevStep,
    handleInputChange,
    handleConfirm,
    setStep,
  } = useBookingForm();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const preSelectedServiceId = params.get('service');
    if (preSelectedServiceId) {
      const preSelectedService = servicesData.find(service => service.id === preSelectedServiceId);
      if (preSelectedService) {
        handleInputChange('selectedServices', [preSelectedService]);
        setStep(2); // Skip to the customise service step if a service is pre-selected
      }
    }
  }, [location, handleInputChange, setStep]);

  const handleSelectSubServices = useCallback((subServices) => {
    handleInputChange('selectedSubServices', subServices);
  }, [handleInputChange]);

  const renderProgressBar = () => (
    <div className={styles.progressBar} role="progressbar" aria-valuemin="1" aria-valuemax="5" aria-valuenow={step}>
      {stepDescriptions.map((description, index) => (
        <div
          key={index}
          className={`${styles.progressStepContainer} ${index + 1 === step ? styles.active : ''}`}
        >
          <div
            className={`${styles.progressStep} ${index + 1 <= step ? styles.active : ''}`}
            aria-label={`Step ${index + 1}: ${description}`}
          >
            {index + 1}
          </div>
          <span className={styles.progressLabel}>{description}</span>
        </div>
      ))}
    </div>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <ServiceGridStep
            services={servicesData}
            onSelectServices={(services) => handleInputChange('selectedServices', services)}
            onNext={nextStep}
            error={fieldErrors.services}
            step={step}
          />
        );
      case 2:
        return (
          <ServiceSelectionStep
            selectedServices={bookingData.selectedServices || []}
            selectedSubServices={bookingData.selectedSubServices || {}}
            subServices={servicesData.reduce((acc, service) => {
              acc[service.id] = service.subServices;
              return acc;
            }, {})}
            onSelectSubService={handleSelectSubServices}
            onNext={nextStep}
            onPrev={prevStep}
            error={fieldErrors.subServices}
            step={step}
          />
        );
      case 3:
        return (
          <PersonalDetailsStep
            personalDetails={bookingData.personalDetails}
            onInputChange={handleInputChange}
            onNext={nextStep}
            onPrev={prevStep}
            errors={fieldErrors}
          />
        );
      case 4:
        return (
          <DateTimeSelectionStep
            onSelectDateTime={(dateTime) => handleInputChange('dateTime', dateTime)}
            onSelectFrequency={(frequency) => handleInputChange('frequency', frequency)}
            onNext={nextStep}
            onPrev={prevStep}
            errors={fieldErrors}
          />
        );
      case 5:
        const formattedDate = bookingData.dateTime && !isNaN(new Date(bookingData.dateTime).getTime())
          ? new Date(bookingData.dateTime).toLocaleString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
          : 'Not specified';
        
        return (
          <SummaryStep
            bookingData={{
              ...bookingData,
              dateTime: formattedDate,
              selectedServices: bookingData.selectedServices.map(service => ({
                ...service,
                description: servicesData.find(s => s.id === service.id)?.description || ''
              })),
              personalDetails: {
                ...bookingData.personalDetails,
                cleaningLocation: bookingData.personalDetails.cleaningLocation
              }
            }}
            onConfirm={handleConfirm}
            onPrev={prevStep}
            isSubmitting={isSubmitting}
            bookingError={bookingError}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.bookingForm}>
      <h2 className={styles.bookingTitle}>Quote in 30 mins or less</h2>
      {renderProgressBar()}
      <div className={styles.stepContent}>
        {renderStep()}
      </div>
      {bookingError && <p className={styles.errorMessage}>{bookingError}</p>}
    </div>
  );
};

export default BookingFormPage;

import React from 'react';
import { motion } from 'framer-motion';
import styles from './SectionTitle.module.css';

export const SectionTitle = ({ children, subtitle, as = 'h2' }) => {
  const HeadingTag = as;
  return (
    <div className={styles.sectionTitle}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <HeadingTag className={styles.sectionHeading}>
          {children}
        </HeadingTag>
        {subtitle && (
          <p className={styles.sectionSubtitle}>
            {subtitle}
          </p>
        )}
      </motion.div>
    </div>
  );
};

import { v4 as uuidv4 } from 'uuid';

const testimonials = [
  {
    id: uuidv4(),
    quote: "Big thanks to Samuel for his exceptional work in clearing my front garden! Your professionalism and timely completion are highly valued. #gardening #appreciation",
    author: "Jane R.", // Fake name
    location: "Burnside, Adelaide",
    service: "Lawn and Backyard Maintenance",
    rating: 5,
    date: "2024-05-15"
  },
  {
    id: 2,
    quote: "Absolutely phenomenal service! They went above and beyond my expectations with their deep residential cleaning. My home has never felt so fresh and inviting. Highly recommend!",
    author: "Sarah T.",
    location: "Norwood, Adelaide",
    service: "Deep Residential Cleaning",
    rating: 5,
    date: "2023-06-15"
  },
  {
    id: 3,
    quote: "Top-notch cleaning crew. They're thorough, efficient, and always leave my office space spotless. Our employees have noticed the difference in air quality and cleanliness.",
    author: "Michael R.",
    location: "CBD, Adelaide",
    service: "Deep Commercial Cleaning",
    rating: 5,
    date: "2023-07-22"
  },
  {
    id: 4,
    quote: "OMG! These guys are AMAZING! 😍 My place has never looked so good. The carpet cleaning service brought my old rugs back to life. You NEED to try them!",
    author: "Emma L.",
    location: "Glenelg, Adelaide",
    service: "Upholstery and Carpet Cleaning",
    rating: 5,
    date: "2023-08-03"
  },
  {
    id: 5,
    quote: "Reliable, professional, and consistently excellent results. They've earned a loyal customer in me. Monthly cleaning has made such a difference in maintaining our home.",
    author: "David W.",
    location: "Prospect, Adelaide",
    service: "Regular Residential Cleaning",
    rating: 5,
    date: "2023-09-10"
  },
  {
    id: 6,
    quote: "I'm impressed with their attention to detail. They noticed and cleaned areas I hadn't even thought about! The deep clean service is worth every penny.",
    author: "Olivia P.",
    location: "Unley, Adelaide",
    service: "Deep Residential Cleaning",
    rating: 5,
    date: "2023-10-05"
  },
  {
    id: 7,
    quote: "I was skeptical at first, but wow! These folks know their stuff. My house is sparkling clean, and they finished faster than I expected. 10/10 would recommend to anyone looking for quality cleaning services.",
    author: "Jake M.",
    location: "Henley Beach, Adelaide",
    service: "Deep Residential Cleaning",
    rating: 5,
    date: "2023-12-01"
  },
  {
    id: 8,
    quote: "I've tried several cleaning services in Adelaide, and OzCleanery is by far the best! Their staff is friendly, professional, and they use eco-friendly products which I love. My allergies have improved since I started using their services. Thank you! 🌿🧼",
    author: "Emily G.",
    location: "St Peters, Adelaide",
    service: "Regular Residential Cleaning",
    rating: 5,
    date: "2024-01-18"
  },
  {
    id: 9,
    quote: "Prompt, efficient, and reasonably priced. What more could you ask for? They've been cleaning our office for months now, and I couldn't be happier with the service. Our workspace feels more productive!",
    author: "Robert K.",
    location: "Kent Town, Adelaide",
    service: "Regular Commercial Cleaning",
    rating: 5,
    date: "2024-02-07"
  },
  {
    id: 10,
    quote: "I'm a busy mum of three, and OzCleanery has been a lifesaver! They come in, work their magic, and suddenly my chaotic house is a peaceful haven. The kids even noticed how nice it smells after they're done! 🏠✨ Absolutely worth every penny!",
    author: "Jessica L.",
    location: "Mitcham, Adelaide",
    service: "Deep Residential Cleaning",
    rating: 5,
    date: "2024-03-14"
  },
  {
    id: 11,
    quote: "As a property manager, I've worked with many cleaning services. OzCleanery stands out for their consistency and professionalism. They've never let me down, even with last-minute requests. Highly recommend for both residential and commercial cleaning needs.",
    author: "Mark W.",
    location: "North Adelaide",
    service: "Commercial and Residential Cleaning",
    rating: 5,
    date: "2024-04-02"
  },
  {
    id: 12,
    quote: "First time using OzCleanery and definitely not the last! My oven looks brand new - I didn't even know it could get that clean! 😮 The deep kitchen clean service is a game-changer. Great job, guys!",
    author: "Alex T.",
    location: "Goodwood, Adelaide",
    service: "Deep Kitchen Clean",
    rating: 5,
    date: "2024-05-09"
  }
];

export default testimonials;

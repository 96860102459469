import ReactGA from 'react-ga';

export const initializeAnalytics = () => {
  ReactGA.initialize('G-WNB3Q4Q7ZW'); // Replace with your Google Analytics tracking ID
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const trackPageView = (path) => {
  ReactGA.pageview(path);
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
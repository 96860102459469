import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './OurServices.module.css';
import { FaHome, FaBuilding, FaLeaf, FaBroom } from 'react-icons/fa';

const serviceIcons = {
  'DEEP RESIDENTIAL CLEANING': FaHome,
  'DEEP COMMERCIAL CLEANING': FaBuilding,
  'LAWN AND BACKYARD MAINTENANCE': FaLeaf,
  'UPHOLSTERY AND CARPET CLEANING': FaBroom,
};

const OurServices = ({ services = [], servicesRef }) => {
  const navigate = useNavigate();

  if (services.length === 0) {
    return <div className={styles.noServices}>No services available at the moment.</div>;
  }

  const handleLearnMore = (serviceId) => {
    navigate(`/services#${serviceId}`);
  };

  const handleBookNow = (serviceId) => {
    navigate(`/booking?service=${serviceId}`);
  };

  return (
    <section className={styles.services}>
      <div className={styles.servicesContainer}>
        {services.map((service, index) => {
          const Icon = serviceIcons[service.homePageContent.category];
          return (
            <div
              key={service.id}
              className={styles.serviceCard}
              ref={el => servicesRef.current[service.id] = el}
            >
              <img
                src={service.homePageContent.image}
                alt={service.homePageContent.title}
                className={styles.serviceImage}
              />
              <div className={styles.overlay}>
                <span className={styles.serviceCategory}>
                  {Icon && <Icon className={styles.serviceIcon} />}
                  {service.homePageContent.category}
                </span>
                <h3 className={styles.serviceTitle}>
                  {service.homePageContent.title.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      {i === 0 && <br />}
                    </React.Fragment>
                  ))}
                </h3>
                {index === 0 && <span className={styles.popularBadge}>Popular</span>}
                <div className={styles.hoverContent}>
                  <p className={styles.serviceDescription}>{service.homePageContent.description}</p>
                  <button
                    onClick={() => handleLearnMore(service.id)}
                    className={styles.learnMoreButton}
                  >
                    Learn More
                    <span className={styles.arrowIcon}>&rarr;</span>
                  </button>
                  <button
                    onClick={() => handleBookNow(service.id)}
                    className={styles.bookNowButton}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.viewAllContainer}>
        <Link to="/services" className={styles.viewAllButton}>
          View All Services
        </Link>
      </div>
    </section>
  );
};

export default OurServices;

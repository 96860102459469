import React, { useState, useEffect, useRef } from 'react';
import styles from './ServiceSelectionStep.module.css';
import commonStyles from './BookingStepCommon.module.css';
import buttonStyles from '../BookingStepButtons.module.css';
import { FaCheck, FaTools } from 'react-icons/fa';

const ServiceSelectionStep = ({ selectedServices, selectedSubServices, subServices, onSelectSubService, onNext, onPrev, error, step }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [localSelectedSubServices, setLocalSelectedSubServices] = useState(selectedSubServices);
  const prevSelectedSubServicesRef = useRef();

  useEffect(() => {
    const initialSelectedSubServices = { ...selectedSubServices };
    selectedServices.forEach(service => {
      if (!initialSelectedSubServices[service.id]) {
        initialSelectedSubServices[service.id] = [];
      }
    });
    setLocalSelectedSubServices(initialSelectedSubServices);
  }, [selectedServices, selectedSubServices]);

  const handleSubServiceToggle = (serviceId, subServiceId) => {
    setLocalSelectedSubServices(prev => {
      const updatedSubServices = { ...prev };
      if (!updatedSubServices[serviceId]) {
        updatedSubServices[serviceId] = [];
      }
      if (updatedSubServices[serviceId].includes(subServiceId)) {
        updatedSubServices[serviceId] = updatedSubServices[serviceId].filter(id => id !== subServiceId);
      } else {
        updatedSubServices[serviceId] = [...updatedSubServices[serviceId], subServiceId];
      }
      return updatedSubServices;
    });
  };

  useEffect(() => {
    if (JSON.stringify(localSelectedSubServices) !== JSON.stringify(prevSelectedSubServicesRef.current)) {
      onSelectSubService(localSelectedSubServices);
      prevSelectedSubServicesRef.current = localSelectedSubServices;
    }
  }, [localSelectedSubServices, onSelectSubService]);

  const handleNext = () => {
    if (isNextButtonDisabled()) {
      const incompleteTabIndex = selectedServices.findIndex(service => 
        !localSelectedSubServices[service.id] || localSelectedSubServices[service.id].length === 0
      );
      setActiveTab(incompleteTabIndex);
    } else {
      onNext();
    }
  };

  const isNextButtonDisabled = () => {
    return selectedServices.some(service => !localSelectedSubServices[service.id] || localSelectedSubServices[service.id].length === 0);
  };

  return (
    <div className={commonStyles.stepContainer}>
      <h2 className={commonStyles.stepTitle}>
        <FaTools className={styles.stepIcon} />
        Customise Your Service
      </h2>
      {error && <p className={commonStyles.errorMessage}>{error}</p>}
      <div className={styles.serviceCustomization}>
        {selectedServices.length > 1 && (
          <div className={styles.serviceTabs}>
            {selectedServices.map((service, index) => (
              <button
                key={service.id}
                className={`${styles.serviceTab} ${index === activeTab ? styles.activeTab : ''} ${(!localSelectedSubServices[service.id] || localSelectedSubServices[service.id].length === 0) ? styles.incompleteTab : ''}`}
                onClick={() => setActiveTab(index)}
              >
                {service.name}
              </button>
            ))}
          </div>
        )}
        <div className={styles.serviceDetails}>
          <h3>{selectedServices[activeTab].name}</h3>
          <p>{selectedServices[activeTab].description}</p>
        </div>
        <div className={styles.subServicesGrid}>
          {subServices[selectedServices[activeTab].id]?.map((subService) => (
            <div
              key={subService.id}
              className={`${styles.subServiceCard} ${localSelectedSubServices[selectedServices[activeTab].id]?.includes(subService.id) ? styles.selected : ''}`}
              onClick={() => handleSubServiceToggle(selectedServices[activeTab].id, subService.id)}
            >
              <div className={styles.subServiceInfo}>
                <span className={styles.subServiceIcon}>
                  {subService.icon && <subService.icon />}
                </span>
                <span className={styles.subServiceName}>{subService.name}</span>
              </div>
              <p className={styles.subServiceDescription}>{subService.description}</p>
              <div className={styles.checkboxContainer}>
                {localSelectedSubServices[selectedServices[activeTab].id]?.includes(subService.id) && (
                  <FaCheck className={styles.checkIcon} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={buttonStyles.navigationButtons}>
        <button onClick={onPrev} className={`${buttonStyles.button} ${buttonStyles.secondaryButton}`}>
          Previous
        </button>
        <button
          onClick={handleNext}
          className={`${buttonStyles.button} ${buttonStyles.primaryButton}`}
          disabled={isNextButtonDisabled()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ServiceSelectionStep;

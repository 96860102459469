import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendToZapier } from '../utils/zapierWebhook';

export const useBookingForm = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [bookingData, setBookingData] = useState({
    selectedServices: [],
    selectedSubServices: {},
    dateTime: null,
    personalDetails: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      cleaningLocation: {
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: ''
      }
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bookingError, setBookingError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('bookingData', JSON.stringify(bookingData));
      localStorage.setItem('bookingStep', step.toString());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [bookingData, step]);

  const nextStep = () => {
    if (validateStep()) {
      setStep(prevStep => {
        const newStep = prevStep + 1;
        window.scrollTo(0, 0);
        return newStep;
      });
    }
  };

  const prevStep = () => setStep(step - 1);

  const validateStep = () => {
    let errors = {};
    switch (step) {
      case 1:
        if (bookingData.selectedServices.length === 0) {
          errors.services = 'Please select at least one service';
        }
        break;
      case 2:
        const hasSelectedSubServices = Object.values(bookingData.selectedSubServices).some(subServices => subServices.length > 0);
        if (!hasSelectedSubServices) {
          errors.subServices = 'Please select at least one sub-service';
        }
        break;
      case 3:
        if (!bookingData.personalDetails.firstName) {
          errors.firstName = 'First name is required';
        }
        if (!bookingData.personalDetails.lastName) {
          errors.lastName = 'Last name is required';
        }
        if (!bookingData.personalDetails.email) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(bookingData.personalDetails.email)) {
          errors.email = 'Email is invalid';
        }
        if (!bookingData.personalDetails.phone) {
          errors.phone = 'Phone number is required';
        }
        break;
      case 4:
        if (!bookingData.dateTime) {
          errors.dateTime = 'Please select a date and time';
        }
        break;
      default:
        break;
    }
    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (field, value) => {
    setBookingData(prevData => {
      let newData = { ...prevData };
      if (field === 'personalDetails') {
        newData.personalDetails = { ...prevData.personalDetails, ...value };
      } else if (field === 'cleaningLocation') {
        newData.personalDetails = {
          ...prevData.personalDetails,
          cleaningLocation: { ...prevData.personalDetails.cleaningLocation, ...value }
        };
      } else {
        newData[field] = value;
      }
      localStorage.setItem('bookingData', JSON.stringify(newData));
      return newData;
    });
  };

  const handleConfirm = async () => {
    if (!validateStep()) {
      return;
    }
    setIsSubmitting(true);
    setBookingError(null);

    try {
      const payload = {
        dateTime: bookingData.dateTime ? bookingData.dateTime.toISOString() : null,
        duration: 30, // 30-minute quote call
        customerName: `${bookingData.personalDetails.firstName} ${bookingData.personalDetails.lastName}`,
        service: 'Quote Call',
        email: bookingData.personalDetails.email,
        phone: bookingData.personalDetails.phone,
        selectedServices: bookingData.selectedServices.map(service => service.name).join(', '),
        cleaningLocation: {
          streetAddress: bookingData.personalDetails.cleaningLocation.streetAddress,
          suburb: bookingData.personalDetails.cleaningLocation.suburb,
          state: bookingData.personalDetails.cleaningLocation.state,
          postcode: bookingData.personalDetails.cleaningLocation.postcode
        },
        selectedSubServices: JSON.stringify(bookingData.selectedSubServices)
      };

      console.log('Sending payload to Zapier:', payload);
      const response = await sendToZapier(payload);
      console.log('Response from Zapier:', response);

      if (response && response.status === 'success') {
        localStorage.removeItem('bookingData');
        localStorage.removeItem('bookingStep');
        navigate('/booking-confirmation', { 
          state: { 
            bookingData: {
              dateTime: payload.dateTime,
              customerName: payload.customerName,
              email: payload.email,
              phone: payload.phone,
              selectedServices: payload.selectedServices,
              cleaningLocation: payload.cleaningLocation,
              bookingId: response.bookingId || 'N/A'
            }
          } 
        });
      } else {
        throw new Error('Received unexpected response from Zapier');
      }
    } catch (error) {
      console.error('Error during quote call booking confirmation:', error);
      setBookingError(`An error occurred: ${error.message}. Please try again later.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    step,
    bookingData,
    isSubmitting,
    bookingError,
    fieldErrors,
    nextStep,
    prevStep,
    handleInputChange,
    handleConfirm,
    setStep,
  };
};

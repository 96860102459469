import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import styles from './BookNowButton.module.css';

const BookNowButton = ({ className, to = '/booking', children = 'Book Now', closeNavbar }) => {
  const handleClick = (e) => {
    if (closeNavbar) {
      closeNavbar();
    }
  };

  return (
    <Link to={to} className={`${styles.bookNowButton} ${className || ''}`} onClick={handleClick}>
      <span className={styles.buttonText}>{children}</span>
      <ChevronRight size={18} className={styles.bookNowArrow} />
    </Link>
  );
};

export default BookNowButton;

import React, { useState, useCallback, lazy, Suspense } from 'react';
import commonStyles from './BookingStepCommon.module.css';
import styles from './DateTimeSelectionStep.module.css';
import buttonStyles from '../BookingStepButtons.module.css';
import { FaClock } from 'react-icons/fa';

const CustomCalendar = lazy(() => import('../CustomCalendar'));

const generateTimeSlots = () => {
  const times = [];
  for (let hour = 9; hour <= 17; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const time = new Date(2000, 0, 1, hour, minute);
      times.push(time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }));
    }
  }
  return times;
};

const DateTimeSelectionStep = ({ onSelectDateTime, onNext, onPrev }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [error, setError] = useState('');

  const updateDateTime = useCallback((date, time) => {
    if (date && time) {
      let dateTime = new Date(date);
      const [hourStr, minuteStr, period] = time.split(/[:\s]/);
      let hour = parseInt(hourStr);
      const minute = parseInt(minuteStr);
      
      if (period === 'PM' && hour !== 12) {
        hour += 12;
      } else if (period === 'AM' && hour === 12) {
        hour = 0;
      }
      
      dateTime.setHours(hour, minute, 0, 0);
      onSelectDateTime(dateTime);
    }
  }, [onSelectDateTime]);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
    setError('');
    if (selectedTime) {
      updateDateTime(date, selectedTime);
    }
  }, [selectedTime, updateDateTime]);

  const handleTimeChange = useCallback((time) => {
    setSelectedTime(time);
    setError('');
    updateDateTime(selectedDate, time);
  }, [selectedDate, updateDateTime]);

  const handleNext = () => {
    if (!selectedDate) {
      setError('Please select a date.');
      return;
    }
    if (!selectedTime) {
      setError('Please select a time.');
      return;
    }
    onNext();
  };

  return (
    <div className={commonStyles.stepContainer}>
      <h2 className={commonStyles.stepTitle}>Select Date and Time for Your 30-Minute Quote Call</h2>
      <div className={styles.calendarSection}>
        <Suspense fallback={<div>Loading calendar...</div>}>
          <CustomCalendar
            onDateSelect={handleDateChange}
            selectedDate={selectedDate}
          />
        </Suspense>
      </div>
      <div className={styles.timeAndFrequencySection}>
        <div className={styles.timeSection}>
          <h3 className={styles.sectionTitle}><FaClock /> Select Time</h3>
          <div className={styles.timeSelectionContainer}>
            <div className={styles.timeGrid}>
              {generateTimeSlots().map((time) => (
                <button
                  key={time}
                  className={`${styles.timeSlot} ${selectedTime === time ? styles.selected : ''}`}
                  onClick={() => handleTimeChange(time)}
                >
                  {time}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
      <div className={buttonStyles.navigationButtons}>
        <button onClick={onPrev} className={`${buttonStyles.button} ${buttonStyles.secondaryButton}`}>
          Previous
        </button>
        <button
          onClick={handleNext}
          className={`${buttonStyles.button} ${buttonStyles.primaryButton}`}
          disabled={!selectedDate || !selectedTime}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DateTimeSelectionStep;

import React, { useState } from 'react';
import stepStyles from './BookingStepCommon.module.css';
import buttonStyles from '../BookingStepButtons.module.css';
import { FaClipboardList } from 'react-icons/fa';

// Step 1: Choose Your Cleaning Category
const ServiceGridStep = ({ services = [], onSelectServices, onNext, step }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [error, setError] = useState('');

  const handleCategorySelect = (service) => {
    setSelectedCategory(service);
    onSelectServices([service]);
    setError('');
  };

  const handleNext = () => {
    if (!selectedCategory) {
      setError('Please select a cleaning category before proceeding.');
    } else {
      onNext();
    }
  };

  const isNextButtonDisabled = () => {
    return !selectedCategory;
  };

  // Ensure we always have 4 items in the grid
  const gridItems = [...services];
  while (gridItems.length < 4) {
    gridItems.push({ id: `empty-${gridItems.length}`, name: '', isEmpty: true });
  }

  return (
    <div className={stepStyles.stepContainer}>
      <h2 className={stepStyles.stepTitle}>
        <FaClipboardList className={stepStyles.stepIcon} />
        Choose Your Cleaning Category
      </h2>
      <div className={stepStyles.serviceGrid}>
        {gridItems.map((service) => (
          <div
            key={service.id}
            className={`${stepStyles.serviceCard} ${
              selectedCategory && selectedCategory.id === service.id ? stepStyles.selected : ''
            } ${service.isEmpty ? stepStyles.emptyCard : ''}`}
            onClick={() => !service.isEmpty && handleCategorySelect(service)}
          >
            {!service.isEmpty && (
              <>
                <div className={stepStyles.serviceIcon}>
                  {service.icon && <service.icon />}
                </div>
                {service.image && <img src={service.image} alt={service.name} className={stepStyles.serviceImage} />}
                <h3 className={stepStyles.serviceName}>{service.name}</h3>
              </>
            )}
          </div>
        ))}
      </div>
      {error && <p className={stepStyles.errorMessage}>{error}</p>}

      <div className={buttonStyles.navigationButtons}>
        <button
          onClick={handleNext}
          className={`${buttonStyles.button} ${buttonStyles.primaryButton}`}
          disabled={isNextButtonDisabled()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ServiceGridStep;

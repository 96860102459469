import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaArrowUp, FaPhone, FaClock, FaMapMarkerAlt } from 'react-icons/fa';
import styles from './Footer.module.css';

const Footer = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToNewsletter = () => {
    const newsletterSection = document.getElementById('newsletter-section');
    if (newsletterSection) {
      newsletterSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.footerSection}>
            <h3>OzCleanery</h3>
            <p>Professional cleaning services for your home and office.</p>
            <div className={styles.socialIcons}>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook"><FaFacebook /></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram"><FaInstagram /></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="Connect with us on LinkedIn"><FaLinkedin /></a>
            </div>
          </div>
          <div className={styles.footerSection}>
            <h3>Services</h3>
            <ul>
              <li><Link to="/services">All Services</Link></li>
              <li><Link to="/services#residential">Residential Cleaning</Link></li>
              <li><Link to="/services#commercial">Commercial Cleaning</Link></li>
              <li><Link to="/services#carpet">Carpet Cleaning</Link></li>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h3>Company</h3>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className={styles.footerSection}>
            <h3>Contact Us</h3>
            <p><FaPhone className={styles.iconMargin} /> 0409 837 053</p>
            <p><FaEnvelope className={styles.iconMargin} /> info@ozcleanery.com</p>
            <p><FaClock className={styles.iconMargin} /> Mon-Fri 9am-9pm, Sat 9am-4pm</p>
            <p><FaMapMarkerAlt className={styles.iconMargin} /> Adelaide, South Australia</p>
          </div>
        </div>
        <div className={styles.newsletterSection}>
          <h3>Newsletter</h3>
          <p>Stay updated with our latest news and offers!</p>
          <button onClick={scrollToNewsletter} className={styles.subscribeButton}>
            <FaEnvelope className={styles.envelopeIcon} />
            Subscribe
          </button>
        </div>
        <div className={styles.bookNowSection}>
          <Link to="/booking" className={styles.bookNowButton}>Book Now</Link>
        </div>
        <div className={styles.bottomBar}>
          <p className={styles.abn}>ABN: 76 679 047 440</p>
          <p className={styles.copyrightText}>&copy; {new Date().getFullYear()} OzCleanery. All rights reserved.</p>
        </div>
      </div>
      {showBackToTop && (
        <button onClick={scrollToTop} className={styles.backToTopButton} aria-label="Back to top">
          <FaArrowUp />
        </button>
      )}
    </footer>
  );
};

export default Footer;

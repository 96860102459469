import React from 'react';
import styles from './SummaryStep.module.css';

// Step 5: Review and Submit
const SummaryStep = ({ bookingData, onConfirm, onPrev, isSubmitting, bookingError }) => {
  const { selectedServices, selectedSubServices, personalDetails, dateTime } = bookingData;
  const { cleaningLocation } = personalDetails;

  const formatServiceName = (name) => {
    return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  return (
    <div className={styles.summaryContainer}>
      <h2 className={styles.stepTitle}>Quote Call Summary</h2>
      <section className={styles.summarySection}>
        <h3 className={styles.sectionTitle}>Services of Interest</h3>
        {selectedServices && selectedServices.length > 0 && selectedServices.map((service) => (
          <div key={service.id} className={styles.serviceItem}>
            <h4>{service.name}</h4>
            <p className={styles.serviceDescription}>{service.description}</p>
            {selectedSubServices && selectedSubServices[service.id] && selectedSubServices[service.id].length > 0 && (
              <div>
                <h5>Specific Services:</h5>
                <ul className={styles.subServicesList}>
                  {selectedSubServices[service.id].map((subService) => (
                    <li key={subService}>
                      {formatServiceName(subService)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </section>

      <section className={styles.summarySection}>
        <h3 className={styles.sectionTitle}>Quote Call Details</h3>
        <div className={styles.detailItem}>
          <span className={styles.detailLabel}>Date and Time:</span>
          <span className={styles.detailValue}>
            {dateTime}
          </span>
        </div>
        <div className={styles.detailItem}>
          <span className={styles.detailLabel}>Duration:</span>
          <span className={styles.detailValue}>30 minutes</span>
        </div>
      </section>

      <section className={styles.summarySection}>
        <h3 className={styles.sectionTitle}>Personal Details</h3>
        <div className={styles.detailItem}>
          <span className={styles.detailLabel}>Name:</span>
          <span className={styles.detailValue}>{personalDetails.firstName} {personalDetails.lastName}</span>
        </div>
        <div className={styles.detailItem}>
          <span className={styles.detailLabel}>Email:</span>
          <span className={styles.detailValue}>{personalDetails.email}</span>
        </div>
        <div className={styles.detailItem}>
          <span className={styles.detailLabel}>Phone:</span>
          <span className={styles.detailValue}>{personalDetails.phone}</span>
        </div>
      </section>

      <section className={styles.summarySection}>
        <h3 className={styles.sectionTitle}>Property Location</h3>
        <div className={styles.detailItem}>
          <span className={styles.detailLabel}>Address:</span>
          <span className={styles.detailValue}>{cleaningLocation.streetAddress}</span>
        </div>
        <div className={styles.detailItem}>
          <span className={styles.detailLabel}>Location:</span>
          <span className={styles.detailValue}>{cleaningLocation.suburb}, {cleaningLocation.state} {cleaningLocation.postcode}</span>
        </div>
      </section>

      {bookingError && <p className={styles.errorMessage}>{bookingError}</p>}

      <div className={styles.navigationButtons}>
        <button onClick={onPrev} className={`${styles.button} ${styles.primaryButton}`} disabled={isSubmitting}>
          Back
        </button>
        <button 
          onClick={onConfirm} 
          className={`${styles.button} ${styles.primaryButton}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Confirm Quote Call'}
        </button>
      </div>
    </div>
  );
};

export default SummaryStep;

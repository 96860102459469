import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CTA.module.css';

const CTA = () => {
  return (
    <section className={styles.ctaSection}>
      <div className={styles.ctaContainer}>
        <h2 className={styles.ctaTitle}>Ready for a Spotless Home?</h2>
        <p className={styles.ctaDescription}>
          Experience the OzCleanery difference today. Book our professional cleaning services and enjoy a pristine living space.
        </p>
        <Link to="/booking" className={styles.ctaButton}>
          Book Now
        </Link>
      </div>
    </section>
  );
};

export default CTA;
